import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { BlockService } from '../../_service/block.service';
import { PartnerService } from '../../_service/partner.service';
import { PartnerModel } from '../../_model/PartnerModel';
import { first } from "rxjs/operators";
import { Router } from "@angular/router";
import { BlockModel } from '../../_model/BlockModel';

@Component({
  selector: 'app-block-edit',
  templateUrl: './block-edit.component.html',
  styleUrls: ['./block-edit.component.css']
})
export class BlockEditComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private router: Router, private blockService: BlockService, private partnerService : PartnerService) { }

  block: BlockModel;
  partner: PartnerModel[];
  editForm: FormGroup;
  submitted = false;

  ngOnInit() {
    this.getAllPartners();

    let blockId = 0
    let blockIdStr = localStorage.getItem("blockId");
    if(!blockIdStr){
      alert("Something wrong!");
      this.router.navigate(['block-list']);
      return;
    } else {
      blockId = parseInt(blockIdStr);
    } 

    this.editForm = this.formBuilder.group({
      id: [],
      vonNr: ['', Validators.required],
      bisNr: ['', Validators.required],
      partnerId: ['', Validators.required],
      aktiv: ['', Validators.required]
    });

    this.blockService.getBlockById(blockId).subscribe(data=>{
      console.log(data);
      this.editForm.patchValue(data); //Don't use editForm.setValue() as it will throw console error
    });
  }
  getAllPartners(): void {
    this.partnerService.getAllPartners().subscribe(data=>{
      this.partner = data;
    });
  };


  // get the form short name to access the form fields
  get f() { return this.editForm.controls; }

  onSubmit(){
    this.submitted = true;
    
    if(this.editForm.valid){
      this.blockService.updateBlock(this.editForm.value)
      .subscribe( data => {
        console.log(data);
        this.router.navigate(['block-list']);
      });
    }
  }
  cancelEdit(){
    this.router.navigate(['block-list']);
  }
}
