import { Component, OnInit,HostListener } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AppGlobals } from '../../app.globals';
import { MonatsreportModel } from '../../_model/MonatsreportModel';
import { FahrscheinService } from '../../_service/fahrschein.service';
import { ModalService } from '../../_service';

import { OfficegenService } from '../../_service/officegen.service';

export enum KEY_CODE {
    ESC = 27
}

@Component({
  selector: 'app-monatsreport',
  templateUrl: './monatsreport.component.html',
  styleUrls: ['./monatsreport.component.css']
})
export class MonatsreportComponent implements OnInit {

  constructor(private fahrscheinService: FahrscheinService,private officegenService: OfficegenService,private modalService: ModalService, private router: Router) { }

  monate:{id:String}[]
  selectMonat: String;
  jahre:{id:String}[]
  selectJahr: String;
  monatsreport: MonatsreportModel[];
  monatsdetail: any[];
  summe: Number;
  anzahl: Number;

  searchText: string;
  searchFieldList: any[];
  searchField: string;

  sortType:string;
  sortReverse:boolean;

  @HostListener('window:keyup',['$event'])
  keyEvent(event:KeyboardEvent) {
      console.log(event)
      if (event.keyCode === KEY_CODE.ESC){
        this.modalService.close('custom-modal-1');
      }
  }

  ngOnInit() {
    this.monate=[{id:'01'},{id:'02'},{id:'03'},{id:'04'},{id:'05'},{id:'06'},
          {id:'07'},{id:'08'},{id:'09'},{id:'10'},{id:'11'},{id:'12'}]
    this.jahre=[]
    
    var now = new Date().toISOString().substr(0, 19);
    this.selectMonat = now.substr(5,2)
    this.selectJahr = now.substr(0,4)
    
    for (var i= 0 ; i < 5 ; i++) {
        var _year = Number(this.selectJahr)
        var obj = {id:(_year - i).toString()}
        this.jahre.push(obj)
    }
//    this.jahre=[{id:'2018'},{id:'2019'},{id:'2020'},{id:'2021'}]

    this.sortType = 'partnername';
    this.sortReverse = false;
    this.searchFieldList = [
      {key:"Name",val:"partnername"},
      {key:"Anzahl",val:"anzahlScheine"},
      {key:"Summe",val:"monatssumme"} ]
    this.searchField = "partnername"

    this.getAllMonatsreport();
  }

  getAllMonatsreport(): void {
    this.fahrscheinService.getMonatsreport(this.selectJahr,this.selectMonat).subscribe(data=>{
      this.monatsreport = data;
    });
  };
  getMonatsdetail(inPartenerId:Number): void {
    this.fahrscheinService.getMonatsdetail(this.selectJahr, this.selectMonat, inPartenerId).subscribe(data=>{
      this.monatsdetail = data;
      this.summe = this.monatsdetail.reduce((partial_sum, a) => partial_sum + a.summe,0);
      this.anzahl = this.monatsdetail.length
    });
  };

  downloadRechnung(zeile: MonatsreportModel) { 
console.log(zeile)  
    this.officegenService.createDocument(zeile)
      .subscribe( data => {
        console.log(data);
        var link = document.createElement('a'); 
        link.href = window.URL.createObjectURL(data); 
        link.setAttribute('download', zeile.partnername+".doc"); 
//        link.setAttribute('download', 'test.doc'); 
        document.body.appendChild(link); 
        link.click(); 
        document.body.removeChild(link); 
      },(err) => {
          console.log(err);
          
      });
  }
  nextMonth() {
    var index = this.monate.findIndex(e => e.id === this.selectMonat)
    if(index == -1 || index > this.monate.length -2 ) {
        this.selectMonat = '01'
    } else { 
        this.selectMonat = this.monate[index+1].id
    }
    this.getAllMonatsreport();
  }
  prevMonth() {
    var index = this.monate.findIndex(e => e.id === this.selectMonat)
    if(index == -1 || index < 1 ) {
        this.selectMonat = '12'
    } else {
        this.selectMonat = this.monate[index-1].id
    }
    this.getAllMonatsreport();
  }
  nextYear() {
    var index = this.jahre.findIndex(e => e.id === this.selectJahr)
    if(index == -1 || index > this.jahre.length -2 ) {
        this.selectJahr = this.jahre[0].id
    } else { 
        this.selectJahr = this.jahre[index+1].id
    }
    this.getAllMonatsreport();
  }
  prevYear() {
    var index = this.jahre.findIndex(e => e.id === this.selectJahr)
    if(index == -1 || index < 1 ) {
        this.selectJahr = this.jahre[this.jahre.length - 1].id
    } else {
        this.selectJahr = this.jahre[index-1].id
    }
    this.getAllMonatsreport();
  }
  selectMonthChanged(newVal) {
    this.selectMonat = newVal  
    this.getAllMonatsreport();
  }
  selectYearChanged(newVal) {
    this.selectJahr = newVal  
    this.getAllMonatsreport();
  }
  openModal(inVal) {
      this.getMonatsdetail(inVal.partnerId)
        this.modalService.open('custom-modal-1');
  }

  closeModal(inVal : any) {
        this.modalService.close('custom-modal-1');
  }

  clickHeader(inVal) {
      this.sortType = inVal;
      this.sortReverse = !this.sortReverse
  }

}
