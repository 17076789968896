import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AppGlobals } from '../../app.globals';
import { PartnerModel } from '../../_model/PartnerModel';
import { PartnerService } from '../../_service/partner.service';

@Component({
  selector: 'app-partners-list',
  templateUrl: './partner-list.component.html',
  styleUrls: ['./partner-list.component.css']
})
export class PartnerListComponent implements OnInit {

  constructor(private partnerService: PartnerService, private router: Router, private _global: AppGlobals) { }

  partners: PartnerModel[];
  showActive = this._global.showOnlyActive;
  searchText: string;
  searchFieldList: any[];
  searchField: string;

  sortType:string;
  sortReverse:boolean;

  ngOnInit() {
    this.searchFieldList = [{key:"Id",val:"id"},{key:"Name",val:"partnername"},{key:"Adresse 1",val:"adresse1"},{key:"Adresse 2",val:"adresse2"},{key:"Prov.",val:"provision"} ]
    this.searchField = "partnername"
    this.getAllPartners();
  }

  getAllPartners(): void {
    this.partnerService.getAllPartners().subscribe(data=>{
      this.partners = data;
    });
  };

  addPartner(): void {
    this.router.navigate(['partner-add']);
  }

  deletePartner(partner: PartnerModel){
    if(window.confirm('Are sure you want to delete this item ?')){
      this.partnerService.deletePartner(partner.id).subscribe(data=>{
        console.log(data);
        this.getAllPartners();
      });
    }
  }

  updatePartner(partner: PartnerModel){
    localStorage.removeItem("partnerId");
    localStorage.setItem("partnerId", partner.id.toString());
    this.router.navigate(['partner-edit']);
  }
  changedActive(e:any) {    
    this.showActive = !this.showActive
    this._global.showOnlyActive = !this._global.showOnlyActive
//    console.log ('-Check changed-',this.showActive)
  }
  clickHeader(inVal) {
      this.sortType = inVal;
      this.sortReverse = !this.sortReverse
  }

}

