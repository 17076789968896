import { Pipe, PipeTransform } from "@angular/core";
@Pipe( {
name: 'isactive'
} )
export class IsActivePipe implements PipeTransform {
transform( array: Array<any>, enabled: boolean ): any[] {
    if (typeof array == 'undefined') {
        return array;
    }
    if (!array) return [];
    if (!enabled) return array;

    return array.filter(t=>t.aktiv ===1);    
  }
}