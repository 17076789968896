import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { FahrscheinService } from '../../_service/fahrschein.service';
import { FahrtService } from '../../_service/fahrt.service';
import { BlockService } from '../../_service/block.service';
import { PartnerService } from '../../_service/partner.service';
import { WochentagService } from '../../_service/wochentag.service';
import { FahrscheinModel } from '../../_model/FahrscheinModel';
import { FahrtModel } from '../../_model/FahrtModel';
import { BlockModel } from '../../_model/BlockModel';
import { first } from "rxjs/operators";
import { Router } from "@angular/router";

@Component({
  selector: 'app-fahrschein-add',
  templateUrl: './fahrschein-add.component.html',
  styleUrls: ['./fahrschein-add.component.css']
})
export class FahrscheinAddComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private router: Router, private fahrtService: FahrtService,private fahrscheinService: FahrscheinService,private blockService: BlockService, private partnerService: PartnerService, private wochentagService: WochentagService) { }

  addForm: FormGroup;
  fahrten: FahrtModel[];
  fahrten_display: FahrtModel[];
  blocks: BlockModel[];
  wochentage: {id: Number, name: String} [];
  
  // form read only components
  mySollSumme: Number
  message: any;
  success_message: any;
  myWochentag: any
  
  submitted = false;

  ngOnInit() {
    this.wochentage = this.wochentagService.getAllWochentage();

    this.getAllFahrten();
    this.getAllBlocks();

    var jetzt = new Date().toISOString().split('T')[0];
    var tagNr = this.berechneWochentag(jetzt);
    var wochentag = this.wochentage[tagNr].name.substring(0,3)
    
    this.addForm = this.formBuilder.group({
      id: [],
      fahrtId: ['', Validators.required],
      gastName: [''],
      datum: [jetzt, Validators.required],
      personen: ['0', Validators.required],
      kinder: ['0', Validators.required],
//      sonstigesPreis: ['0',Validators.pattern(/^d*(?:[.,]d{1,2})?$/)],
//      sonstigesPreis: ['0',Validators.pattern(/^-?\d+(,|\.)\d+$/)],
      sonstigesPreis: ['0',Validators.pattern(/^-?\d+(,|\.|d)?\d*$/)],
      sonstigesText: [''],
//      summe: ['0',Validators.pattern(/^-?\d+(,|\.)\d+$/)],
      summe: ['',[Validators.pattern(/^-?\d+(,|\.|d)?\d*$/),Validators.required]],
      wochentag: [wochentag],
      blockId: [null],
      Partnername: [''],
      SollSumme: [this.mySollSumme]
    });
  }
  cancelEdit(){
    this.router.navigate(['']);
  }

  getAllFahrten(): void {
    this.fahrtService.getAllFahrten().subscribe(data=>{
      this.fahrten = data;
      this.fahrten = this.fahrten.sort((n1,n2) => Number(n1.fahrtnummer) - Number(n2.fahrtnummer));
      this.fahrten_display = this.fahrten ;
    });
  };
  getAllBlocks(): void {
    this.blockService.getAllBlocks().subscribe(data=>{
      this.blocks = data;
    });
  };

  scheinNrChanged(e: any) {
      this.message = null
      this.success_message = null
      console.log (this.addForm.value.id)
      var myFahrscheinId = this.addForm.value.id
      // check if Fahrschein already exists
      this.fahrscheinService.getFahrscheinById(myFahrscheinId).subscribe(data=>{
             var retval = data;
             if (retval != null) {
                this.addForm.patchValue({id: "?"+myFahrscheinId+"?"});
                this.message = "Schein schon vorhanden"
             }
      })
      var aktBlock = this.blocks.find( block => block.vonNr <= myFahrscheinId && block.bisNr >= myFahrscheinId);
      if (aktBlock == null) {
          console.log ("no Block found")
          this.message = "no Block found"
          this.addForm.patchValue({blockId: null})
      }   else {
          console.log (aktBlock)
          this.addForm.patchValue({BlockId: aktBlock.id});
          this.partnerService.getPartnerById(aktBlock.partnerId).subscribe(data=>{
             var partner = data;
             if (partner != null) {
               this.addForm.patchValue({blockId: aktBlock.id, Partnername: partner.partnername});
             }
          });
      }   
  }
  datumChanged(e: any) {
      console.log (this.addForm.value.datum)
      this.message = null
      var tagNr = this.berechneWochentag(this.addForm.value.datum)  // 0 = kein 1=Mon 7=Son vgl. wochentag.service 
      console.log(tagNr)
      this.addForm.patchValue({wochentag: this.wochentage[tagNr].name.substring(0,3)});
      this.fahrten_display = this.fahrten.filter(fahrt => (Number(fahrt.wochentag) == tagNr || Number(fahrt.wochentag) == 0))
      if (this.fahrten_display.length == 0) {
        this.message = "keine Fahrt an "+this.wochentage[tagNr].name
      }
  }
  berechneWochentag(inDatumString) {
    var jetzt = new Date(inDatumString)
    var tag = jetzt.getDate()
    var tagZahl = jetzt.getDay()
        if (tagZahl == 0 ) tagZahl = 7
    return tagZahl	
  } 
  calcFieldChanged() {
      console.log ("in Change")
      var anzPers = this.addForm.value.personen
      var anzKind = this.addForm.value.kinder
      var eurSonst = this.addForm.value.sonstigesPreis
      var fahrtId = this.addForm.value.fahrtId
      var fahrt = this.fahrten.find(f => f.id == fahrtId)
      if (fahrt != null) {
          this.mySollSumme = Number(fahrt.preisPerson) * anzPers + Number(fahrt.preisKind) * anzKind + Number(eurSonst)
//          console.log (this.mySollSumme)
          this.addForm.patchValue({SollSumme: this.mySollSumme})
      }
  }
  resetFields() {
	  this.addForm.patchValue({id:"",gastName:"",personen: 0,kinder:0,sonstigesPreis:0,sonstigesText:"",summe:0,SollSumme:1})
//	  this.calcFieldChanged()
	  document.getElementById("id").focus()
  }

  saveExit() {
    this.submitted = true;
    
    if(this.addForm.valid){
      this.fahrscheinService.addFahrschein(this.addForm.value)
      .subscribe( data => {
        console.log(data);
        this.router.navigate(['']);
      },(err) => {
          console.log(err);
          this.scheinNrChanged('event')
          this.message = err
      });
    }
  }
  onSubmit(){
    this.submitted = true;
    
    if(this.addForm.valid){
      this.fahrscheinService.addFahrschein(this.addForm.value)
      .subscribe( data => {
        console.log(data);
//        this.router.navigate(['fahrt-list']);
        this.success_message = '-gespeichert-' +this.addForm.value.id
		this.resetFields()
      },(err) => {
          console.log(err);
          this.scheinNrChanged('event')
          this.message = err
          
      });
    }
  }
  testReset(){
		this.resetFields()
  }

  // get the form short name to access the form fields
  get f() { return this.addForm.controls; }

}
