import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PartnerModel } from '../_model/PartnerModel';
import { AppGlobals } from '../app.globals';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(private http: HttpClient,private _global: AppGlobals) { }

//  baseurl: string = "http://localhost:3000/api/";
  baseurl: string = this._global.baseAppUrl;

  getAllPartners(){
    return this.http.get<PartnerModel[]>(this.baseurl + 'getAllPartners');
  }

  getPartnerById(id: Number){
    return this.http.get<PartnerModel>(this.baseurl + 'getPartnerById' + '/' + id);
  }

  getPartnerByBlockId(id: Number){
    return this.http.get<PartnerModel>(this.baseurl + 'getPartnerByBlockId' + '/' + id);
  }

  addPartner(partner: PartnerModel){
    return this.http.post(this.baseurl + 'addPartner', partner);
  }

  deletePartner(id: Number){
    return this.http.delete(this.baseurl + 'delPartner' + '/' + id);
  }

  updatePartner(partner: PartnerModel){
    return this.http.put(this.baseurl + 'updPartner' + '/' + partner.id, partner);
  }
}

