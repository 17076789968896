<div class="container col-md-12"> 
  <div class="row">
    <div class="col-sm-6">
      <div class="tile orange">
        <a routerLink='fahrschein-add'>
          <h3 class="title">Fahrschein eingeben</h3>
        </a>
      </div>
    </div>
    <p></p>
  </div>
  <!-- *********************************** -->
  <div class="row">
    <div class="col-sm-6">
      <div class="tile orange">
        <a routerLink="fahrschein-list">
          <h3 class="title">Fahrscheine</h3>
        </a>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="tile blue">
        <a routerLink='report-monat'>
          <h3 class="title">Monatsliste</h3>
        </a>
      </div>
    </div>
  </div>
  <!-- *********************************** -->
  <div class="row">
    <div class="col-sm-6">
      <div class="tile green">
        <a routerLink='partner-list'>
          <h3 class="title">Partnerliste</h3>
        </a>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="tile blue">
        <a routerLink='report-partner'>
          <h3 class="title">Partner Auswertung</h3>
        </a>
      </div>
    </div>
  </div>
  <!-- *********************************** -->
  <div class="row">
    <div class="col-sm-6">
      <div class="tile green">
        <a routerLink='block-list'>
          <h3 class="title">Blockliste</h3>
        </a>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="tile blue">
        <a routerLink='report-block'>
          <h3 class="title">Fahrschein Blöcke</h3>
        </a>
      </div>
    </div>
  </div>
  <!-- *********************************** -->
  <div class="row">
    <div class="col-sm-6">
      <div class="tile green">
        <a routerLink='fahrt-list'>
          <h3 class="title">Fahrtenliste</h3>
        </a>
      </div>
    </div>
  </div>
</div>