import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AppGlobals } from '../../app.globals';
import { BlockModel } from '../../_model/BlockModel';
import { BlockService } from '../../_service/block.service';

@Component({
  selector: 'app-block-list',
  templateUrl: './block-list.component.html',
  styleUrls: ['./block-list.component.css']
})
export class BlockListComponent implements OnInit {

  constructor(private blockService: BlockService, private router: Router, private _global: AppGlobals) { }

  blocks: BlockModel[];
  showActive = this._global.showOnlyActive;
  searchText: string;
  searchFieldList: any[];
  searchField: string;

  sortType:string;
  sortReverse:boolean;


  ngOnInit() {
    this.searchFieldList = [{key:"Id",val:"id"},{key:"vonNr",val:"vonNr"},{key:"bisNr",val:"bisNr"},{key:"PartnerID",val:"partnerid"},{key:"Name",val:"partnername"} ]
    this.searchField = "partnername"
    this.getAllBlocks();
  }

  getAllBlocks(): void {
    this.blockService.getAllBlocks().subscribe(data=>{
      this.blocks = data;
    });
  };

  addBlock(): void {
    this.router.navigate(['block-add']);
  }

  deleteBlock(block: BlockModel){
    if(window.confirm('Are sure you want to delete this item ?')){
      this.blockService.deleteBlock(block.id).subscribe(data=>{
        console.log(data);
        this.getAllBlocks();
      });
    }
  }

  updateBlock(block: BlockModel){
    localStorage.removeItem("blockId");
    localStorage.setItem("blockId", block.id.toString());
    this.router.navigate(['block-edit']);
  }
  changedActive(e:any) {    
    this.showActive = !this.showActive
    this._global.showOnlyActive = !this._global.showOnlyActive
//    console.log ('-Check changed-',this.showActive)
  }
  clickHeader(inVal) {
      this.sortType = inVal;
      this.sortReverse = !this.sortReverse
  }

}
