<!--The content below is only a placeholder and can be replaced.-->
<!-- icons: https://material.io/resources/icons/?style=baseline  -->
<nav class="navbar navbar-expand-sm bg-info navbar-light">
    <div class="container">
        <a class="navbar-brand" routerLink="">
          <img src="assets/FunkLogo80.gif" alt="Logo" style="width:40px;">
        </a>
        <ul class="nav navbar-nav" routerLinkActive="active">
            <li class="nav-item"><a class="nav-link" routerLink=""><i class="material-icons">home</i></a></li>
            <li class="nav-item"><a class="nav-link" routerLink="fahrschein-add">Eingeben</a></li>
            <li class="nav-item"><a class="nav-link" routerLink="fahrschein-list">Fahrscheine</a></li>
            <li class="nav-item"><a class="nav-link" routerLink="report-monat">Abrechnung</a></li>
            <li class="nav-item dropdown">
                <div id="" class="nav-link dropdown-toggle"  data-toggle="dropdown" href="#" >
                    Reports
                </div>
                <div class="dropdown-menu">
                    <a class="dropdown-item" routerLink="report-block">Fahrschein Blöcke</a>
                    <a class="dropdown-item" routerLink="report-monat">Monats Report</a>
                    <a class="dropdown-item" routerLink="report-partner">Partner Report</a>
                </div>
            </li>
            <li class="divider"></li>
            <li class="nav-item dropdown">
                <div id="" class="nav-link dropdown-toggle"  data-toggle="dropdown" href="#" >
                    Listen
                </div>
                <div class="dropdown-menu">
                    <a class="dropdown-item" routerLink="block-list">Block Liste</a>
                    <a class="dropdown-item" routerLink="fahrt-list">Fahrten Liste</a>
                    <a class="dropdown-item" routerLink="partner-list">Partner Liste</a>
                </div>
            </li>
        </ul>
        <span class="navbar-brand" style="width: 400px;text-align: center;">
          {{ title }}
        </span>
        <span class="nav navbar-nav" style="allign:right">
            <a class="nav-link" routerLink="logout"><i class="material-icons">power_settings_new</i></a>
        </span>
    </div>
</nav>

<!-- main app container -->
<div class="jumbotron">
    <div class="container">
        <div class="row">
<!--            <div class="col-md-12 offset-md-0">  -->
            <div class="col-md-12">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
