import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_service/authentication.service';

@Component({
  template: ''
})

export class LogoutComponent implements OnInit {

  constructor(        
    private router: Router,
    private authenticationService: AuthenticationService
) {}

  ngOnInit() {
    this.authenticationService.logout();
    this.router.navigate(['login']);
  }

}