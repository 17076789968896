import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { BlockService } from '../../_service/block.service';
import { PartnerService } from '../../_service/partner.service';
import { PartnerModel } from '../../_model/PartnerModel';
import { first } from "rxjs/operators";
import { Router } from "@angular/router";

@Component({
  selector: 'app-block-add',
  templateUrl: './block-add.component.html',
  styleUrls: ['./block-add.component.css']
})
export class BlockAddComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private router: Router, private blockService: BlockService, private partnerService : PartnerService) { }

  addForm: FormGroup;
  partner: PartnerModel[];
  submitted = false;

  ngOnInit() {
    this.getAllPartners();

    this.addForm = this.formBuilder.group({
      _id: [],
      vonNr: ['', Validators.required],
      bisNr: ['', Validators.required],
      partnerId: ['', Validators.required],
      aktiv: ['1', Validators.required],
    });
  }
  cancelEdit(){
    this.router.navigate(['block-list']);
  }
  getAllPartners(): void {
    this.partnerService.getAllPartners().subscribe(data=>{
      this.partner = data;
    });
  };

  onSubmit(){
    this.submitted = true;
    
    if(this.addForm.valid){
      this.blockService.addBlock(this.addForm.value)
      .subscribe( data => {
        console.log(data);
        this.router.navigate(['block-list']);
      });
    }
  }

  // get the form short name to access the form fields
  get f() { return this.addForm.controls; }

}
