import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { PartnerService } from '../../_service/partner.service';
import { first } from "rxjs/operators";
import { Router } from "@angular/router";
import { PartnerModel } from '../../_model/PartnerModel';

@Component({
  selector: 'app-partner-edit',
  templateUrl: './partner-edit.component.html',
  styleUrls: ['./partner-edit.component.css']
})
export class PartnerEditComponent implements OnInit {

  partner: PartnerModel;
  editForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private partnerService: PartnerService) { }

  ngOnInit() {
    let partnerId = 0
    let partnerIdStr = localStorage.getItem("partnerId");
    if(!partnerIdStr){
      alert("Something wrong!");
      this.router.navigate(['partner-list']);
      return;
    } else {
      partnerId = parseInt(partnerIdStr);
    } 

    this.editForm = this.formBuilder.group({
      id: [],
      partnername: ['', Validators.required],
      adresse1: ['', ],
      adresse2: ['', Validators.required],
      provision: ['', Validators.required],
      aktiv: ['', Validators.required]
    });

    this.partnerService.getPartnerById(partnerId).subscribe(data=>{
      console.log(data);
      this.editForm.patchValue(data); //Don't use editForm.setValue() as it will throw console error
    });
  }

  // get the form short name to access the form fields
  get f() { return this.editForm.controls; }

  onSubmit(){
    this.submitted = true;
    
    if(this.editForm.valid){
      this.partnerService.updatePartner(this.editForm.value)
      .subscribe( data => {
        console.log(data);
        this.router.navigate(['partner-list']);
      });
    }
  }
  cancelEdit(){
    this.router.navigate(['partner-list']);
  }
}
