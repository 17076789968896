<div class="container col-md-12"> 
    <div>
        <select [(ngModel)]="selectMonat" (change)="selectMonthChanged($event.target.value)" class="form-control-sm">
            <option *ngFor="let monat of monate" 
               [value]="monat.id"
               [attr.selected]="selectMonat==monat.id ? true : null">{{monat.id}}
            </option>
        </select>
        <button (click)="nextMonth()" class="fa fa-caret-down"></button>
        <button (click)="prevMonth()" class="fa fa-caret-up" ></button>
        <select  [(ngModel)]="selectJahr" style="margin-left: 15px" (change)="selectYearChanged($event.target.value)" class="form-control-sm">
            <option *ngFor="let jahr of jahre" 
               [value]="jahr.id" 
               [attr.selected]="selectJahr==jahr.id ? true : null">{{jahr.id}}
            </option>
        </select>
<!--        <select  [(ngModel)]="selectJahr" name="selectJahr" style="margin-left: 15px" (change)="selectYearChanged($event.target.value)" class="form-control-sm">
            <option *ngFor="let jahr of jahre" 
               [value]="jahr.id" 
               [attr.selected]="selectJahr==jahr.id ? true : null">{{jahr.id}}
            </option>
        </select>
-->
        <button (click)="nextYear()" class="fa fa-caret-down"></button>
        <button (click)="prevYear()" class="fa fa-caret-up"></button>
        <label for="searchText" style="margin-left: 30px">Suche: </label>
        <select name="searchField" [(ngModel)]="searchField" class="form-control-sm">
            <option *ngFor="let feld of searchFieldList" 
               [value]="feld.val" >{{feld.key}}
            </option>
        </select>
        <input type="text" name="searchText" class="form-control-sm" [(ngModel)]="searchText" >

    </div> 
  <h2>Monats Report</h2>  

  <div class="table-responsive table-container">  
    <table class="table table-light table-hover table-striped">  
      <thead class="thead-light">  
        <tr>  
          <th (click)="clickHeader('partnername')">Partner
            <span *ngIf="sortType == 'partnername' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'partnername' && sortReverse" class="fa fa-caret-up"></span>
          </th>
          <th (click)="clickHeader('anzahlScheine')">Anzahl Fahrscheine
            <span *ngIf="sortType == 'anzahlScheine' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'anzahlScheine' && sortReverse" class="fa fa-caret-up"></span>
          </th>
          <th style="text-align:right" (click)="clickHeader('monatssumme')">Summe
            <span *ngIf="sortType == 'monatssumme' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'monatssumme' && sortReverse" class="fa fa-caret-up"></span>
          </th>
          <th>Action</th> 
        </tr>  
      </thead>  
      <tbody>  
        <tr *ngFor="let zeile of monatsreport | searchfilter : searchText : searchField | orderBy:sortType: sortReverse"> 
          <td>{{zeile.partnername}}</td>  
          <td>{{zeile.anzahlScheine}}</td>
          <td style="text-align:right">{{zeile.monatssumme | number :'1.2-2':'de'}}</td>
          <td>  
            <button (click)="openModal(zeile)" class="btn btn-info">Detail</button>  

            <button (click)="downloadRechnung(zeile)" style="margin-left: 20px;" class="btn btn-info">Rechnung</button>  
          </td>  
        </tr>  
      </tbody>  
    </table>  
  </div> 
   
</div> 
<modal id="custom-modal-1">
  <div class="modal-class">
    <div class="modal-body">
      <h1>Report Details</h1>
      <button (click)="closeModal('custom-modal-1');" style="float: left" class="btn btn-info">close</button> 
      <div style="margin-left: 20px; float:left"><strong>Summe: {{summe}} / Anzahl: {{anzahl}} </strong></div>
      <div class="table-responsive table-container">  
        <table class="table table-light table-hover table-striped">  
          <thead class="thead-light">  
            <tr>  
              <th>Nr</th> 
              <th>Fahrt</th> 
              <th>Datum</th> 
              <th>Erw.</th> 
              <th>K.</th> 
              <th style="text-align:right">Sont.€</th> 
              <th>Bemerkung</th> 
              <th style="text-align:right">Summe</th> 
            </tr>  
          </thead>  
          <tbody>  
            <tr *ngFor="let zeile of monatsdetail"> 
              <td>{{zeile.id}}</td>
              <td>{{zeile.fahrtName}}</td>
              <td>{{zeile.datum}}</td>
              <td>{{zeile.personen}}</td>
              <td>{{zeile.kinder}}</td>
              <td style="text-align:right">{{zeile.sonstigesPreis | number :'1.2-2':'de'}}</td>
              <td>{{zeile.sonstigesText}}</td>
              <td style="text-align:right">{{zeile.summe | number :'1.2-2':'de'}}</td>
            </tr>  
          </tbody>  
        </table>  
      </div> 
    </div>
  </div>
  <div class="modal-background"></div>
</modal>
