import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { FahrtService } from '../../_service/fahrt.service';
import { WochentagService } from '../../_service/wochentag.service';
import { first } from "rxjs/operators";
import { Router } from "@angular/router";

@Component({
  selector: 'app-fahrt-add',
  templateUrl: './fahrt-add.component.html',
  styleUrls: ['./fahrt-add.component.css']
})
export class FahrtAddComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private router: Router, private fahrtService: FahrtService,private wochentagService: WochentagService) { }

  addForm: FormGroup;
  wochentage: {id: Number, name: String} [];
  submitted = false;

  ngOnInit() {
    this.wochentage = this.wochentagService.getAllWochentage();

    this.addForm = this.formBuilder.group({
      _id: [],
      fahrtname: ['', Validators.required],
      fahrtnummer: ['', Validators.required],
      wochentag: ['0', Validators.required],
      preisPerson: ['', Validators.required],
      preisKind: ['', Validators.required],
      aktiv: ['1', Validators.required],
    });
  }
  cancelEdit(){
    this.router.navigate(['fahrt-list']);
  }

  onSubmit(){
    this.submitted = true;
    
    if(this.addForm.valid){
      this.fahrtService.addFahrt(this.addForm.value)
      .subscribe( data => {
        console.log(data);
        this.router.navigate(['fahrt-list']);
      });
    }
  }

  // get the form short name to access the form fields
  get f() { return this.addForm.controls; }

}
