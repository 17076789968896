import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { FahrtService } from '../../_service/fahrt.service';
import { WochentagService } from '../../_service/wochentag.service';
import { first } from "rxjs/operators";
import { Router } from "@angular/router";
import { FahrtModel } from '../../_model/FahrtModel';

@Component({
  selector: 'app-fahrt-edit',
  templateUrl: './fahrt-edit.component.html',
  styleUrls: ['./fahrt-edit.component.css']
})
export class FahrtEditComponent implements OnInit {

  fahrt: FahrtModel;
  editForm: FormGroup;
  wochentage: {id: Number, name: String} [];
  submitted = false;
  

  constructor(private formBuilder: FormBuilder, private router: Router, private fahrtService: FahrtService, private wochentagService: WochentagService) { }

  ngOnInit() {
    let fahrtId = 0
    let fahrtIdStr = localStorage.getItem("fahrtId");
    if(!fahrtIdStr){
      alert("Something wrong!");
      this.router.navigate(['fahrt-list']);
      return;
    } else {
      fahrtId = parseInt(fahrtIdStr);
    } 
    this.wochentage = this.wochentagService.getAllWochentage();

    this.editForm = this.formBuilder.group({
      id: [],
      fahrtnummer: ['', Validators.required],
      fahrtname: ['', Validators.required],
      wochentag: ['', Validators.required],
      preisPerson: ['', Validators.required],
      preisKind: ['', Validators.required],
      aktiv: ['', Validators.required]
    });
    
    this.fahrtService.getFahrtById(fahrtId).subscribe(data=>{
      console.log(data);
      this.editForm.patchValue(data); //Don't use editForm.setValue() as it will throw console error
    });
  }

  // get the form short name to access the form fields
  get f() { return this.editForm.controls; }

  onSubmit(){
    this.submitted = true;
    
    if(this.editForm.valid){
      this.fahrtService.updateFahrt(this.editForm.value)
      .subscribe( data => {
        console.log(data);
        this.router.navigate(['fahrt-list']);
      });
    }
  }
  cancelEdit(){
    this.router.navigate(['fahrt-list']);
  }
}
