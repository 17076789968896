import { Pipe, PipeTransform } from "@angular/core";
@Pipe( {
name: 'searchfilter'
} )
export class SearchFilterPipe implements PipeTransform {
transform( array: Array<any>, searchText: string, searchField: string ): any[] {
    if (typeof array == 'undefined') {
        return array;
    }
    if (!array) return [];
    if (!searchText) return array;
    if (!searchField) return array;

//    return array;
    searchText = searchText.toLowerCase()
    
    return array.filter(it => {
        let strval = String(it[searchField])
      return strval.toLowerCase().includes(searchText)  
    })
    
  }
}