<!--<div class="container col-md-12"> -->
<div class="container col-md-12"> 
  <div class="row">
	  <div class="col-md-9">
        <button (click)="addFahrschein()" class="col-md-2 btn btn-info">Add Fahrschein</button>
        <input type="checkbox" id="showYear" name="showYear" [checked]="showYear" (change)="changedYear($event)"  style="margin-left: 15px"/>
        <label for="showYear">nur akt.Jahr</label>
        <label for="searchText" style="margin-left: 30px">Suche: </label>
        <select name="searchField" [(ngModel)]="searchField" class="form-control-sm">
            <option *ngFor="let feld of searchFieldList" 
               [value]="feld.val" >{{feld.key}}
            </option>
        </select>
        <input type="text" name="searchText" class="form-control-sm" [(ngModel)]="searchText" >
    </div> 
  </div> 
  <h2>All Fahrscheine ({{((fahrscheine | searchfilter : searchText : searchField)||"").length}})</h2>  

  <div class="table-responsive table-container">  
    <table class="table table-light table-hover table-striped"> 
      <thead class="thead-light">  
        <tr>  
          <th (click)="clickHeader('id')">Id
            <span *ngIf="sortType == 'id' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'id' && sortReverse" class="fa fa-caret-up"></span>
          </th>
          <th (click)="clickHeader('fahrtId')">Fahrt 
            <span *ngIf="sortType == 'fahrtId' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'fahrtId' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th (click)="clickHeader('blockId')">Block
          <span *ngIf="sortType == 'blockId' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'blockId' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th (click)="clickHeader('partnername')">Partner
            <span *ngIf="sortType == 'partnername' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'partnername' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th (click)="clickHeader('datum')">Datum
            <span *ngIf="sortType == 'datum' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'datum' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th (click)="clickHeader('gastName')">Gast
            <span *ngIf="sortType == 'gastName' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'gastName' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th style="text-align:right" (click)="clickHeader('summe')">Sum[€]
            <span *ngIf="sortType == 'summe' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'summe' && sortReverse" class="fa fa-caret-up"></span>
         </th> 
          <th (click)="clickHeader('personen')">Erw.
            <span *ngIf="sortType == 'personen' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'personen' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th (click)="clickHeader('kinder')">Kin.
            <span *ngIf="sortType == 'kinder' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'kinder' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th style="text-align:right" (click)="clickHeader('sonstigesPreis')">Sonst[€]
            <span *ngIf="sortType == 'sonstigesPreis' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'sonstigesPreis' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th (click)="clickHeader('sonstigesText')">Bemerkung
            <span *ngIf="sortType == 'sonstigesText' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'sonstigesText' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th>Action
          </th> 
        </tr>  
      </thead>  
      <tbody>  
        <tr *ngFor="let fahrschein of fahrscheine | thisyear : showYear : currentYear |searchfilter : searchText : searchField | orderBy:sortType: sortReverse as filtered"> 
          <td>{{fahrschein.id}}</td>  
          <td>{{fahrschein.fahrtId}}</td>
          <td>{{fahrschein.blockId}}</td>
          <td>{{fahrschein.partnername}}</td>
          <td nowrap>{{fahrschein.datum}}</td> 
          <td>{{fahrschein.gastName}}</td> 
          <td style="text-align:right"><b>{{fahrschein.summe | number :'1.2-2':'de'}}</b></td> 
          <td>{{fahrschein.personen}}</td> 
          <td>{{fahrschein.kinder}}</td> 
          <td style="text-align:right">{{fahrschein.sonstigesPreis | number :'1.2-2':'de'}}</td> 
          <td>{{fahrschein.sonstigesText}}</td> 
          <td style="white-space:nowrap">  
            <button (click)="updateFahrschein(fahrschein)" class="btn btn-info"> Edit</button>  
            <button (click)="deleteFahrschein(fahrschein)" style="margin-left: 2px;" class="btn btn-info"> Delete</button>
          </td>  
        </tr>  
      </tbody>  
    </table>  
  </div> 
   
</div> 

