import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WochentagService {

  constructor() { }

  getAllWochentage(){
    return [{id:0,name:"-Alle Tage-"},   {id:1,name:"Montag"},    {id:2,name:"Dienstag"},
            {id:3,name:"Mittwoch"}, {id:4,name:"Donnerstag"},{id:5,name:"Freitag"},
            {id:6,name:"Samstag"},  {id:7,name:"Sonntag"}];
  }
}
