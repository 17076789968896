<div class="container col-md-12"> 
    <div>
        <select name="selectPartnerId" (change)="selectChanged($event.target.value)" class="form-control-sm">
            <option *ngFor="let p of partners" 
               [value]="p.id"
               [attr.selected]="selectPartnerId==p.id ? true : null">{{p.partnername}}
            </option>
        </select>
        <button (click)="nextPartner()" class="fa fa-caret-down"></button>
        <button (click)="prevPartner()" class="fa fa-caret-up" ></button>
        <label for="searchText" style="margin-left: 30px">Suche: </label>
        <select name="searchField" [(ngModel)]="searchField" class="form-control-sm">
            <option *ngFor="let feld of searchFieldList" 
               [value]="feld.val" >{{feld.key}}
            </option>
        </select>
        <input type="text" name="searchText" class="form-control-sm" [(ngModel)]="searchText" >
    </div> 
  <h2>Partner Report</h2>  
<button *ngIf="isFahrscheinOn" (click)="changeView()" class="btn btn-info fa"> > Blöcke</button>
<button *ngIf="!isFahrscheinOn" (click)="changeView()" class="btn btn-info fa"> > Fahrscheine</button>
  <div *ngIf="isFahrscheinOn" class="table-responsive table-container">  
    <table class="table table-light table-hover table-striped">  
      <thead class="thead-light">  
        <tr>  
          <th (click)="clickHeader('jahr')">Jahr
            <span *ngIf="sortType == 'jahr' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'jahr' && sortReverse" class="fa fa-caret-up"></span>
          </th>
          <th (click)="clickHeader('monat')">Monat
            <span *ngIf="sortType == 'monat' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'monat' && sortReverse" class="fa fa-caret-up"></span>
          </th>
          <th (click)="clickHeader('anzahlScheine')">Anzahl Fahrscheine
            <span *ngIf="sortType == 'anzahlScheine' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'anzahlScheine' && sortReverse" class="fa fa-caret-up"></span>
          </th>
          <th style="text-align:right" (click)="clickHeader('monatssumme')">Summe
            <span *ngIf="sortType == 'monatssumme' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'monatssumme' && sortReverse" class="fa fa-caret-up"></span>
          </th>
          <th>Action
          </th> 
        </tr>  
      </thead>  
      <tbody>  
        <tr *ngFor="let zeile of partnerreport | searchfilter : searchText : searchField | orderBy:sortType: sortReverse"> 
          <td>{{zeile.jahr}}</td>  
          <td>{{zeile.monat}}</td>  
          <td>{{zeile.anzahlScheine}}</td>
          <td style="text-align:right">{{zeile.monatssumme | number :'1.2-2':'de'}}</td>
          <td>  
<!--            <button (click)="detail(zeile)" class="btn btn-info">Detail</button>  -->
            <button (click)="openModal(zeile)" class="btn btn-info">Detail</button>  
            <button (click)="downloadRechnung(zeile)" style="margin-left: 20px;" class="btn btn-info">Rechnung</button>  
          </td>  
        </tr>  
      </tbody>  
    </table>  
  </div>
  <div *ngIf="!isFahrscheinOn" class="table-responsive table-container">  
    <table class="table table-light table-hover table-striped">  
      <thead class="thead-light">  
        <tr>  
          <th>Id</th>  
          <th>vonNr</th> 
          <th>bisNr</th> 
          <th>Offene Scheine</th> 
          <th>Aktiv</th> 
          <th>Action</th> 
        </tr>  
      </thead>  
      <tbody>  
        <tr *ngFor="let block of blocks"> 
           
          <td>{{block.id}}</td>  
          <td>{{block.vonNr}}</td>
          <td>{{block.bisNr}}</td> 
          <td>{{block.anzahl - block.gebucht}}</td> 
          <td><input *ngIf="block.aktiv == 1; else notChecked" type="checkbox" checked disabled/>
             <ng-template #notChecked>
               <input type="checkbox" unchecked disabled/>
             </ng-template>
          </td>   
          <td>  
            <button (click)="gotoBlock(block)" class="btn btn-info">zu Block</button>  
          </td>  
        </tr>  
      </tbody>  
    </table>  
  </div> 
  
</div>

<modal id="custom-modal-1">
  <div class="modal-class">
    <div class="modal-body">
      <h1>Report Details</h1>
      <button (click)="closeModal('custom-modal-1');" style="float: left" class="btn btn-info">close</button> 
      <div style="margin-left: 20px; float:left"><strong>Summe: {{summe}} / Anzahl: {{anzahl}} </strong></div>
      <div class="table-responsive table-container">  
        <table class="table table-light table-hover table-striped">  
          <thead class="thead-light">  
            <tr>  
              <th>Nr</th> 
              <th>Fahrt</th> 
              <th>Datum</th> 
              <th>Erw.</th> 
              <th>K.</th> 
              <th style="text-align:right">Sont.€</th> 
              <th>Bemerkung</th> 
              <th style="text-align:right">Summe</th> 
            </tr>  
          </thead>  
          <tbody>  
            <tr *ngFor="let zeile of monatsdetail | searchfilter : searchText : searchField | orderBy:sortType: sortReverse"> 
              <td>{{zeile.id}}</td>
              <td>{{zeile.fahrtName}}</td>
              <td>{{zeile.datum}}</td>
              <td>{{zeile.personen}}</td>
              <td>{{zeile.kinder}}</td>
              <td style="text-align:right">{{zeile.sonstigesPreis | number :'1.2-2':'de'}}</td>
              <td>{{zeile.sonstigesText}}</td>
              <td style="text-align:right">{{zeile.summe | number :'1.2-2':'de'}}</td>
            </tr>  
          </tbody>  
        </table>  
      </div> 
    </div>
  </div>
  <div class="modal-background"></div>
</modal>
