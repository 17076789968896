import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FahrscheinModel } from '../_model/FahrscheinModel';
import { MonatsreportModel } from '../_model/MonatsreportModel';
import { AppGlobals } from '../app.globals';

@Injectable({
  providedIn: 'root'
})
export class FahrscheinService {

  constructor(private http: HttpClient,private _global: AppGlobals) { }

//  baseurl: string = "http://localhost:3000/api/";
  baseurl: string = this._global.baseAppUrl;

  getAllFahrscheine(){
    return this.http.get<FahrscheinModel[]>(this.baseurl + 'getAllFahrscheine');
  }

  getFahrscheinById(id: Number){
    return this.http.get<FahrscheinModel>(this.baseurl + 'getFahrscheinById' + '/' + id);
  }
  getFahrscheineByBlockId(bid: Number){
    return this.http.get<FahrscheinModel[]>(this.baseurl + 'getFahrscheineByBlockId' +'/'+bid);
  }

  addFahrschein(fahrschein: FahrscheinModel){
    return this.http.post(this.baseurl + 'addFahrschein', fahrschein);
  }

  deleteFahrschein(id: Number){
    return this.http.delete(this.baseurl + 'delFahrschein' + '/' + id);
  }

/*   no edit Fahrschein. delete and add please */
  updateFahrschein(fahrschein: FahrscheinModel){
    return this.http.put(this.baseurl + 'updFahrschein' + '/' + fahrschein.id, fahrschein);
  }
  getMonatsreport(jahr: String, monat:String){
    return this.http.get<MonatsreportModel[]>(this.baseurl + 'getMonatsReport' + '/' + jahr+ '/' + monat);
  }
  getPartnerreport(id:Number){
    return this.http.get<MonatsreportModel[]>(this.baseurl + 'getPartnerReportById' + '/' + id);
  }
  getMonatsdetail(jahr: String, monat:String, id:Number){
    return this.http.get<any[]>(this.baseurl + 'getMonatsDetail' + '/' + jahr+ '/' + monat+ '/' + id);
  }
}
