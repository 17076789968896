<div class="container col-md-12"> 
    <div class="form-group">
        <button (click)="addBlock()" class="btn btn-info">Add Block</button>
        <input type="checkbox" id="showActive" name="showActive" [checked]="showActive" (change)="changedActive($event)"  style="margin-left: 15px"/>
        <label for="showActive">nur aktive</label>
        <label for="searchText" style="margin-left: 30px">Suche: </label>
        <select name="searchField" [(ngModel)]="searchField" class="form-control-sm">
            <option *ngFor="let feld of searchFieldList" 
               [value]="feld.val" >{{feld.key}}
            </option>
        </select>
        <input type="text" name="searchText" class="form-control-sm" [(ngModel)]="searchText" >
    </div> 
  <h2>All Blocks</h2>  
  <div class="table-responsive table-container">  
    <table class="table table-light table-hover table-striped">  
      <thead class="thead-light">  
        <tr>  
          <th (click)="clickHeader('id')">ID
            <span *ngIf="sortType == 'id' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'id' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th (click)="clickHeader('vonNr')">vonNr
            <span *ngIf="sortType == 'vonNr' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'vonNr' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th (click)="clickHeader('bisNr')">bisNr
            <span *ngIf="sortType == 'bisNr' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'bisNr' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th (click)="clickHeader('partnerId')">PartnerID
            <span *ngIf="sortType == 'partnerId' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'partnerId' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th (click)="clickHeader('partnername')">Name
            <span *ngIf="sortType == 'partnername' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'partnername' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th (click)="clickHeader('aktiv')">Aktiv
            <span *ngIf="sortType == 'aktiv' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'aktiv' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th>Action</th> 
        </tr>  
      </thead>  
      <tbody>  
        <tr *ngFor="let block of blocks | isactive: showActive | searchfilter : searchText : searchField | orderBy:sortType: sortReverse"> 
          <td>{{block.id}}</td>  
          <td>{{block.vonNr}}</td>
          <td>{{block.bisNr}}</td> 
          <td>{{block.partnerId}}</td> 
          <td>{{block.partnername}}</td>  
          <td><input *ngIf="block.aktiv == 1; else notChecked" type="checkbox" checked disabled/>
             <ng-template #notChecked>
               <input type="checkbox" unchecked disabled/>
             </ng-template>
          </td>           
           
          <td>  
            <button (click)="deleteBlock(block)" class="btn btn-info"> Delete</button>  
            <button (click)="updateBlock(block)" style="margin-left: 20px;" class="btn btn-info"> Edit</button>  
          </td>  
        </tr>  
      </tbody>  
    </table>  
  </div> 
   
</div> 
