import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AppGlobals } from '../../app.globals';
import { PartnerModel } from '../../_model/PartnerModel';
import { MonatsreportModel } from '../../_model/MonatsreportModel';
import { BlockModel } from '../../_model/BlockModel';
import { PartnerService } from '../../_service/partner.service';
import { FahrscheinService } from '../../_service/fahrschein.service';
import { BlockService } from '../../_service/block.service';
import { ModalService } from '../../_service';

import { OfficegenService } from '../../_service/officegen.service';

export enum KEY_CODE1 {
    ESC = 27
}

@Component({
  selector: 'app-partnerreport',
  templateUrl: './partnerreport.component.html',
  styleUrls: ['./partnerreport.component.css']
})
export class PartnerreportComponent implements OnInit {

  constructor(private partnerService: PartnerService, private fahrscheinService: FahrscheinService, private blockService: BlockService,private officegenService: OfficegenService, private modalService: ModalService, private router: Router, private aRoute: ActivatedRoute) { }

  selectPartnerId: Number;
  partners: PartnerModel[];
  partnerreport: MonatsreportModel[];
  blocks: BlockModel[];
  monatsdetail: any[];
  summe: Number;
  anzahl: Number;

  searchText: string;
  searchFieldList: any[];
  searchField: string;

  sortType:string;
  sortReverse:boolean;
  
  isFahrscheinOn:Boolean;
  
  @HostListener('window:keyup',['$event'])
  keyEvent(event:KeyboardEvent) {
//      console.log(event)
      if (event.keyCode === KEY_CODE1.ESC){
        this.modalService.close('custom-modal-1');
      }
  }
  ngOnInit() {
    this.sortType = 'jahr';
    this.sortReverse = false;
    this.searchFieldList = [{key:"Jahr",val:"jahr"},
      {key:"Monat",val:"monat"},
      {key:"Anzahl",val:"anzahlScheine"},
      {key:"Summe",val:"monatssumme"} ]
    this.searchField = "jahr"
    this.isFahrscheinOn = true;

    this.getAllPartners();
  }

  getAllPartners(): void {
    this.partnerService.getAllPartners().subscribe(data=>{
      this.partners = data;
      if (this.partners.length > 0) {
        if (this.aRoute.snapshot.paramMap.get('ID')) {
            this.selectPartnerId = parseInt(this.aRoute.snapshot.paramMap.get('ID'))
        } else 
            this.selectPartnerId = this.partners[0].id
        this.getAllPartnerreport() 
        this.getBlocks4Partner()        
      }
    });
  };

  getAllPartnerreport(): void {
    this.fahrscheinService.getPartnerreport(this.selectPartnerId).subscribe(data=>{
      this.partnerreport = data;
    });
  };
  getBlocks4Partner(): void {
    this.blockService.getBlocksByPartnerId(this.selectPartnerId).subscribe(data=>{
      this.blocks = data;
    });
  };
  getMonatsdetail(inYear:String, inMonth: String): void {
    this.fahrscheinService.getMonatsdetail(inYear, inMonth, this.selectPartnerId).subscribe(data=>{
      this.monatsdetail = data;
      this.summe = this.monatsdetail.reduce((partial_sum, a) => partial_sum + a.summe,0);
      this.anzahl = this.monatsdetail.length
    });
  };

  nextPartner() {
    var index = this.partners.findIndex(e => e.id === this.selectPartnerId)
    if(index == -1 || index > this.partners.length -2 ) {
        this.selectPartnerId = this.partners[0].id
    } else { 
        this.selectPartnerId = this.partners[index+1].id
    }
    this.getAllPartnerreport();
    this.getBlocks4Partner();        

  }
  prevPartner() {
    var index = this.partners.findIndex(e => e.id === this.selectPartnerId)
    if(index == -1 || index < 1 ) {
        this.selectPartnerId = this.partners[this.partners.length - 1].id
    } else {
        this.selectPartnerId = this.partners[index-1].id
    }
    this.getAllPartnerreport();
    this.getBlocks4Partner()        ;
  }
  selectChanged(newVal) {
    this.selectPartnerId = newVal  
    this.getAllPartnerreport();
    this.getBlocks4Partner()        
  }
  changeView() {
    this.isFahrscheinOn = !this.isFahrscheinOn  
  }
  openModal(inVal) {
      this.getMonatsdetail(inVal.jahr,inVal.monat)
        this.modalService.open('custom-modal-1');
  }

  closeModal(inVal) {
        this.modalService.close('custom-modal-1');
  }
  gotoBlock(inVal) {
        localStorage.removeItem("blockId");
        localStorage.setItem("blockId", inVal.id.toString());
        this.router.navigate(['report-block']);
  }
  downloadRechnung(zeile: MonatsreportModel) { 
console.log(zeile)  
    this.officegenService.createDocument(zeile)
      .subscribe( data => {
        console.log(data);
        var link = document.createElement('a'); 
        link.href = window.URL.createObjectURL(data); 
        link.setAttribute('download', 'test.doc'); 
        document.body.appendChild(link); 
        link.click(); 
        document.body.removeChild(link); 
      },(err) => {
          console.log(err);
          
      });
  }
  clickHeader(inVal) {
      this.sortType = inVal;
      this.sortReverse = !this.sortReverse
  }
}
