import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AppGlobals } from '../../app.globals';
import { FahrtModel } from '../../_model/FahrtModel';
import { FahrtService } from '../../_service/fahrt.service';
import { WochentagService } from '../../_service/wochentag.service';


@Component({
  selector: 'app-fahrt-list',
  templateUrl: './fahrt-list.component.html',
  styleUrls: ['./fahrt-list.component.css']
})
export class FahrtListComponent implements OnInit {

  constructor(private fahrtService: FahrtService, private router: Router, private _global: AppGlobals, private wochentagService: WochentagService) { }

  fahrten: FahrtModel[];
  wochentage: {id: Number, name: String} [];
  showActive = this._global.showOnlyActive;

  ngOnInit() {

    this.wochentage = this.wochentagService.getAllWochentage();
    this.getAllFahrten();
  }

  getAllFahrten(): void {
    this.fahrtService.getAllFahrten().subscribe(data=>{
      this.fahrten = data;
    });
  };

  addFahrt(): void {
    this.router.navigate(['fahrt-add']);
  }

  deleteFahrt(fahrt: FahrtModel){
    if(window.confirm('Are sure you want to delete this item ?')){
      this.fahrtService.deleteFahrt(fahrt.id).subscribe(data=>{
        console.log(data);
        this.getAllFahrten();
      });
    }
  }
  updateFahrt(fahrt: FahrtModel){
    localStorage.removeItem("fahrtId");
    localStorage.setItem("fahrtId", fahrt.id.toString());
    this.router.navigate(['fahrt-edit']);
  }

  changedActive(e: any) {    
    this.showActive = !this.showActive
    this._global.showOnlyActive = !this._global.showOnlyActive
//    console.log ('-Check changed-',this.showActive)
  }
}
