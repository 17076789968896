<div class="col-md-3">
  <h2 class="text-center">Edit Product</h2>
  <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label>Title:</label>
      <input type="text" formControlName="title" placeholder="Title" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
      <div *ngIf="submitted && f.title.errors">
        <div *ngIf="f.title.errors.required">Title is required</div>
      </div>
    </div>

    <div class="form-group">
      <label>Description:</label>
      <input type="text" formControlName="description" placeholder="Description" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.description.errors }">
      <div *ngIf="submitted && f.description.errors">
        <div *ngIf="f.description.errors.required">Description is required</div>
      </div>
    </div>

    <div class="form-group">
      <label>Price ($):</label>
      <input type="text" formControlName="price" placeholder="Price" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.price.errors }">
      <div *ngIf="submitted && f.price.errors">
        <div *ngIf="f.price.errors.required">Price is required</div>
      </div>
    </div>

    <div class="form-group">
        <label>Company:</label>
        <input type="text" formControlName="company" placeholder="Company" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
        <div *ngIf="submitted && f.company.errors">
          <div *ngIf="f.company.errors.required">Company is required</div>
        </div>
    </div>

    <button type="submit"  class="btn btn-info">Update</button>
    <button type="button"  class="btn btn-default" (click)="cancelEdit()">Cancel</button>
  </form>
</div>

