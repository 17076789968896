import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BlockModel } from '../_model/BlockModel';
import { AppGlobals } from '../app.globals';

@Injectable({
  providedIn: 'root'
})
export class BlockService {

  constructor(private http: HttpClient,private _global: AppGlobals) { }

//  baseurl: string = "http://localhost:3000/api/";
  baseurl: string = this._global.baseAppUrl;

  getAllBlocks(){
    console.log(this.baseurl)

    return this.http.get<BlockModel[]>(this.baseurl + 'getAllBlocks');
  }

  getBlockById(id: Number){
    return this.http.get<BlockModel>(this.baseurl + 'getBlockById' + '/' + id);
  }

  getNextBlockByVonNr(vonNr: Number){
    return this.http.get<BlockModel>(this.baseurl + 'getNextBlockByVonNr' + '/' + vonNr);
  }

  getPrevBlockByVonNr(vonNr: Number){
    return this.http.get<BlockModel>(this.baseurl + 'getPrevBlockByVonNr' + '/' + vonNr);
  }
  
  getBlockByFahrscheinId(fid: Number){
    return this.http.get<BlockModel>(this.baseurl + 'getBlockByFahrscheinId' + '/' + fid);
  }
  getBlocksByPartnerId(pid: Number){
    return this.http.get<BlockModel[]>(this.baseurl + 'getBlocksByPartner' + '/' + pid);
  }

  addBlock(block: BlockModel){
    return this.http.post(this.baseurl + 'addBlock', block);
  }

  deleteBlock(id: Number){
    return this.http.delete(this.baseurl + 'delBlock' + '/' + id);
  }

  updateBlock(block: BlockModel){
    return this.http.put(this.baseurl + 'updBlock' + '/' + block.id, block);
  }
  
}
