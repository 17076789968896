import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { LoginComponent, LogoutComponent} from './login';
import { ListProductsComponent } from './list-products/list-products.component';
import { AddProductComponent } from './add-product/add-product.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { PartnerListComponent,PartnerAddComponent,PartnerEditComponent } from './partner';
import { BlockListComponent,BlockAddComponent,BlockEditComponent } from './block';
import { FahrtListComponent,FahrtAddComponent,FahrtEditComponent } from './fahrt';
import { FahrscheinAddComponent,FahrscheinListComponent, FahrscheinEditComponent } from './fahrschein';
import { BlockreportComponent, MonatsreportComponent, PartnerreportComponent } from './report';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: '', component: HomeComponent, pathMatch: 'full',canActivate: [AuthGuard] },
  //
  { path: 'partner-list', component: PartnerListComponent,canActivate: [AuthGuard]},
  { path: 'partner-add',  component: PartnerAddComponent, canActivate: [AuthGuard]},
  { path: 'partner-edit', component: PartnerEditComponent,canActivate: [AuthGuard]},
  //
  { path: 'block-list',   component: BlockListComponent,  canActivate: [AuthGuard]},
  { path: 'block-add',    component: BlockAddComponent,   canActivate: [AuthGuard]},
  { path: 'block-edit',   component: BlockEditComponent,  canActivate: [AuthGuard]},
  //
  { path: 'fahrt-list',   component: FahrtListComponent,  canActivate: [AuthGuard]},
  { path: 'fahrt-add',    component: FahrtAddComponent,   canActivate: [AuthGuard]},
  { path: 'fahrt-edit',   component: FahrtEditComponent,  canActivate: [AuthGuard]},
  //
  { path: 'fahrschein-add',  component: FahrscheinAddComponent,   canActivate: [AuthGuard]},
  { path: 'fahrschein-list', component: FahrscheinListComponent,  canActivate: [AuthGuard]},
  { path: 'fahrschein-edit', component: FahrscheinEditComponent,  canActivate: [AuthGuard]},
  //
  { path: 'report-block',   component: BlockreportComponent,  canActivate: [AuthGuard]},
  { path: 'report-monat',   component: MonatsreportComponent,  canActivate: [AuthGuard]},
  { path: 'report-partner', component: PartnerreportComponent,  canActivate: [AuthGuard]},
  { path: 'report-partner/:ID', component: PartnerreportComponent,  canActivate: [AuthGuard]},
//**********************************
  { path: 'add-product', component: AddProductComponent },
  { path: 'edit-product', component: EditProductComponent},
  { path: 'list-product', component: ListProductsComponent},
  { path: '**', redirectTo:'',pathMatch:'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
