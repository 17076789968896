<div class="container col-md-12"> 
    <div>
        <button (click)="addFahrt()" class="btn btn-info">Add Fahrt</button>&nbsp;
        <input type="checkbox" id="showActive" name="showActive" [checked]="showActive" (change)="changedActive($event)" style="margin-left: 15px"/>
        <label for="showActive">nur aktive</label>
    </div> 
  <h2>All Fahrten</h2>  

  <div class="table-responsive table-container">  
    <table class="table table-light table-hover table-striped">  
      <thead class="thead-light">  
        <tr>  
          <th>Id</th>  
          <th>Fahrt Nummer</th> 
          <th>Fahrt Bezeichnng</th> 
          <th>Wochentag</th> 
          <th>Preis Erw</th> 
          <th>Preis Kind</th> 
          <th>Aktiv</th> 
          <th>Action</th> 
        </tr>  
      </thead>  
      <tbody>  
        <tr *ngFor="let fahrt of fahrten | isactive: showActive"> 
          <td>{{fahrt.id}}</td>  
          <td>{{fahrt.fahrtnummer}}</td>
          <td>{{fahrt.fahrtname}}</td>
          <td>{{wochentage[fahrt.wochentag].name}}</td> 
          <td>{{fahrt.preisPerson | number :'1.2-2':'de'}}</td> 
          <td>{{fahrt.preisKind | number :'1.2-2':'de'}}</td> 
<!--          <td>{{fahrt.aktiv}}</td>  --> 
          <td><input *ngIf="fahrt.aktiv == 1; else notChecked" type="checkbox" checked disabled/>
             <ng-template #notChecked>
               <input type="checkbox" unchecked disabled/>
             </ng-template>
          </td>           
          <td nowrap>  
            <button (click)="deleteFahrt(fahrt)" class="btn btn-info"> Delete</button>  
            <button (click)="updateFahrt(fahrt)" style="margin-left: 20px;" class="btn btn-info"> Edit</button>  
          </td>  
        </tr>  
      </tbody>  
    </table>  
  </div> 
   
</div> 
