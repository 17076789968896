import { Pipe, PipeTransform } from "@angular/core";
@Pipe( {
name: 'thisyear'
} )
export class ThisYearPipe implements PipeTransform {
transform( array: Array<any>, enabled: boolean, jahr: String ): any[] {
    if (typeof array == 'undefined') {
        return array;
    }
    if (!array) return [];
    if (!enabled) return array;

    return array.filter(t=>t.datum.substr(0,4) == jahr);    
  }
}
