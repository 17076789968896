import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppGlobals {
    readonly serverUrl:  string = window.location.protocol+'//'+window.location.host;
    readonly baseAppUrl: string = window.location.protocol+'//'+window.location.host+'/api/';
// **** test *****
//    readonly serverUrl:  string = 'https://funk.bp2006.de:5002/';
//    readonly baseAppUrl: string = 'https://funk.bp2006.de:5002/api/';
//    readonly serverUrl:  string = 'https://funk.bp2006.de/';
//    readonly baseAppUrl: string = 'https://funk.bp2006.de/api/';
//    readonly serverUrl:  string = 'http://localhost:3001/';
//    readonly baseAppUrl: string = 'http://localhost:3001/api/';
//    readonly serverUrl:  string = 'http://zf.bp2006.de:3001/';
//    readonly baseAppUrl: string = 'http://zf.bp2006.de:3001/api/';
    public showOnlyActive: boolean = true;
}