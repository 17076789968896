<div class="container col-md-12"> 
  <div class="row">
	  <div class="col-md-9">
		 <button (click)="prevBlock()" class="btn btn-info mybtn fa fa-caret-left"> prev</button>
		 <button (click)="nextBlock()" class="btn btn-info mybtn fa fa-caret-right"> next</button>
			<label for="searchText" style="margin-left: 30px">Suche Fahrschein: &nbsp;</label>
			<input type="text" name="searchText" class="form-control-sm" (keyup.enter)="Suche()" [(ngModel)]="searchText" >
			<button (click)="Suche()" class="btn btn-info fa"> Suche</button>

	  </div> 
	  <div class="col-md-3">
		<span class="text-danger">ROT: genutzte Fahrscheine</span><br/>
		<span class="text-primary">BLAU: ungenutzte Fahrscheine </span>
	  </div> 
  </div> 
  <h2>Fahrschein Blöcke</h2>  
  <form [formGroup]="editForm" class="form-row">
      <div class="form-group col-md-1">
        <label for="vonNr">vonNr:</label>
        <input type="text" formControlName="vonNr" placeholder="vonNr" class="form-control" readonly>
      </div>
      <div class="form-group col-md-1">
        <label for="bisNr">bisNr:</label>
        <input type="text" formControlName="bisNr" placeholder="bisNr" class="form-control" readonly>
      </div>
      <div class="form-group col-md-1">
        <label>Aktiv:</label>
        <input type="text" formControlName="aktiv" placeholder="Aktiv" class="form-control" readonly>
      </div>
  
      <div class="form-group col-md-1">
        <label>PartnerId:</label>
        <input type="text" formControlName="partnerId" placeholder="PartnerId" class="form-control" readonly>
      </div>
      <div class="form-group col-md-3">
        <label>Partner:</label>
        <input type="text" formControlName="partnername" placeholder="PartnerName" class="form-control" readonly>
      </div>
      <div class="form-group col-md-5">
        <label>Adresse:</label>
        <input type="text" formControlName="adresse2" placeholder="Adresse" class="form-control" readonly>
      </div>
  </form>
  <div class="popover" *ngIf="popoverForElement!=null">{{popoverMessage}}</div>  
<!--  <div class="{{popoverClass}}" *ngIf="popoverForElement!=null">{{popoverMessage}}</div> -->
  <h3>Fahrscheine</h3>  
  <div class="table-responsive">
    <table class="table table-hover table-striped table-bordered ">
        <tbody>
            <tr *ngFor="let n of lines;let i = index">
                <td class="text-primary" *ngFor="let n of headline;let j = index">
                  <div *ngIf="vonNr+c > vonNr+i*10+j">
                    <div  *ngIf="nrListe.includes(vonNr+i*10+j); then thenTemplateName else elseTemplateName"></div>
                        <ng-template #thenTemplateName>
                            <span class='text-danger ' (mouseover)="showPopover($event,vonNr+i*10+j)" (mouseout)="hidePopover()">{{vonNr+i*10+j}}</span>
                            <div class="popover" *ngIf="popoverForElement==(vonNr+i*10+j)">{{popoverMessage}}</div>
                        </ng-template>
                        <ng-template #elseTemplateName>
                            {{vonNr+i*10+j}}
                        </ng-template>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
  </div>
</div>