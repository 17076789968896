import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { BlockService } from '../../_service/block.service';
import { PartnerService } from '../../_service/partner.service';
import { FahrscheinService } from '../../_service/fahrschein.service';
import { FahrscheinModel } from '../../_model/fahrscheinModel';


@Component({
  selector: 'app-blockreport',
  templateUrl: './blockreport.component.html',
  styleUrls: ['./blockreport.component.css']
})
export class BlockreportComponent implements OnInit {

  fahrscheine: FahrscheinModel[];

  constructor(private formBuilder: FormBuilder, private router: Router, private blockService: BlockService,private partnerService: PartnerService,private fahrscheinService: FahrscheinService) { }

  editForm: FormGroup;
  headline : Number[] ;
  vonNr : any;
  bisNr : any;
  c : any;
  nrListe : Number[] ;
  lines : Number[] ;
  popoverForElement: Number;
  popoverMessage:String;
/*  popoverClass: {
	"position":"absolute",
	"z-index" : 100,
//	"left" : "100px",
//	"top" : "100px"
  } */
  blockId :Number;
  searchText: string;


  ngOnInit() {
    this.popoverForElement = null;
    this.popoverMessage = null;
    this.headline = [1,2,3,4,5,6,7,8,9,0]
    
    this.editForm = this.formBuilder.group({
      id: [],
      vonNr:      [''],
      bisNr:      [''],
      partnerId:  [''],
      aktiv:      [''],
      partnername:[''],
      adresse2:    ['']
    });

    this.blockId = 0
    let blockIdStr = localStorage.getItem("blockId");
    if(!blockIdStr){
//      alert("check here Guenter");
      this.vonNr = 1
      this.nextBlock();
    } else {
      this.blockId = parseInt(blockIdStr);
      this.getFahrscheinForBlock(this.blockId)
    } 


  }
  getFahrscheinForBlock(bid): void {
    this.fahrscheinService.getFahrscheineByBlockId(bid).subscribe(data=>{
      this.fahrscheine = data;
      this.nrListe = this.fahrscheine.map(({ id }) => id);
      this.getBlockData(bid)
    });
  };
  getBlockData(bid): void {
    this.blockService.getBlockById(bid).subscribe(data=>{
      console.log(data);
      this.editForm.patchValue(data); //Don't use editForm.setValue() as it will throw console error
      this.vonNr = data.vonNr
      console.log(data.vonNr)
      this.bisNr = data.bisNr
      this.c = this.bisNr - this.vonNr + 1
//      this.nrListe = [151,153,154,160]
      this.lines = Array.from(Array(Math.floor((this.c - 1) / 10)+1).keys())
      this.partnerService.getPartnerById(data.partnerId).subscribe(data=>{
        console.log(data);
        delete data.aktiv
        this.editForm.patchValue(data); //Don't use editForm.setValue() as it will       });    
      });
    })
  }
  
  showPopover(evt,inVal) {
//	console.log(evt.srcElement)  
    this.popoverForElement = inVal; 
    let mydata = this.fahrscheine.find(x => x.id === inVal)
    this.popoverMessage = "Datum:"+mydata.datum+" | Erw:"+mydata.personen+" | Ki:"+mydata.kinder+" | Summe:"+mydata.summe
  };

  hidePopover() {
    this.popoverForElement = null;
    this.popoverMessage = null;
  };

  prevBlock() {
    this.blockService.getPrevBlockByVonNr(this.vonNr).subscribe(data=>{
        console.log(data);
        if ( !(data == null) ) {
            console.log("navigate");
            localStorage.removeItem("blockId");
            localStorage.setItem("blockId", data.id.toString());
            // navigate to same page 
            this.getFahrscheinForBlock(data.id)
        }
    })      
  };
  nextBlock() {
    this.blockService.getNextBlockByVonNr(this.vonNr).subscribe(data=>{
        console.log(data);
        if ( !(data == null) ) {
            console.log("navigate");
            localStorage.removeItem("blockId");
            localStorage.setItem("blockId", data.id.toString());
            // navigate to same page 
            this.getFahrscheinForBlock(data.id)
        }
    })      
  };
  Suche() {
    if (this.searchText == "") return; 
    this.blockService.getBlockByFahrscheinId(Number(this.searchText)).subscribe(data=>{
        console.log(data);
        if ( !(data == null) ) {
            console.log("navigate");
            localStorage.removeItem("blockId");
            localStorage.setItem("blockId", data.id.toString());
            // navigate to same page 
            this.getFahrscheinForBlock(data.id)
        }
    })      
  };


}
