import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { PartnerService } from '../../_service/partner.service';
import { first } from "rxjs/operators";
import { Router } from "@angular/router";

@Component({
  selector: 'app-partner-add',
  templateUrl: './partner-add.component.html',
  styleUrls: ['./partner-add.component.css']
})
export class PartnerAddComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private router: Router, private partnerService: PartnerService) { }

  addForm: FormGroup;
  submitted = false;

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      _id: [],
      partnername: ['', Validators.required],
      adresse1: [''],
      adresse2: ['', Validators.required],
      provision: ['', Validators.required],
      aktiv: ['1', Validators.required],
    });
  }
  cancelEdit(){
    this.router.navigate(['partner-list']);
  }

  onSubmit(){
    this.submitted = true;
    
    if(this.addForm.valid){
      this.partnerService.addPartner(this.addForm.value)
      .subscribe( data => {
        console.log(data);
        this.router.navigate(['']);
      });
    }
  }

  // get the form short name to access the form fields
  get f() { return this.addForm.controls; }

}
