import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from "@angular/forms"; 
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

import { JwtInterceptor, ErrorInterceptor, OrderByPipe, IsActivePipe,SearchFilterPipe,ThisYearPipe } from './_helpers';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppGlobals } from './app.globals';

import { ProductService,PartnerService,UserService,AuthenticationService,ModalService } from './_service';
import { ModalComponent } from './_directives';

import { ListProductsComponent } from './list-products/list-products.component';
import { AddProductComponent } from './add-product/add-product.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { PartnerListComponent,PartnerAddComponent,PartnerEditComponent } from './partner';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './login/logout.component';
import { BlockListComponent } from './block/block-list/block-list.component';
import { BlockAddComponent } from './block/block-add/block-add.component';
import { BlockEditComponent } from './block/block-edit/block-edit.component';
import { FahrtEditComponent } from './fahrt/fahrt-edit/fahrt-edit.component';
import { FahrtAddComponent } from './fahrt/fahrt-add/fahrt-add.component';
import { FahrtListComponent } from './fahrt/fahrt-list/fahrt-list.component';
import { BlockreportComponent } from './report/blockreport/blockreport.component';
import { HomeComponent } from './home/home.component';
import { FahrscheinAddComponent } from './fahrschein/fahrschein-add/fahrschein-add.component';
import { FahrscheinListComponent } from './fahrschein/fahrschein-list/fahrschein-list.component';
import { FahrscheinEditComponent } from './fahrschein/fahrschein-edit/fahrschein-edit.component';
import { MonatsreportComponent } from './report/monatsreport/monatsreport.component';
import { PartnerreportComponent } from './report/partnerreport/partnerreport.component';

registerLocaleData(localeDe,'de');

@NgModule({
  declarations: [
    OrderByPipe,
    IsActivePipe,
    SearchFilterPipe,
	ThisYearPipe,
    AppComponent,
    ListProductsComponent,
    AddProductComponent,
    EditProductComponent,
    LoginComponent,
    LogoutComponent,
    ModalComponent,
    PartnerListComponent,
    PartnerAddComponent,
    PartnerEditComponent,
    BlockListComponent,
    BlockAddComponent,
    BlockEditComponent,
    FahrtEditComponent,
    FahrtAddComponent,
    FahrtListComponent,
    BlockreportComponent,
    HomeComponent,
    FahrscheinAddComponent,
    FahrscheinListComponent,
    FahrscheinEditComponent,
    MonatsreportComponent,
    PartnerreportComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [        
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AppGlobals,  
    ProductService,PartnerService,UserService,AuthenticationService,ModalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
