<div class="col-md-6">
    <h2 class="text-center">Add Block</h2>
    <form [formGroup]="addForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label>vonNr:</label>
        <input type="text" formControlName="vonNr" placeholder="vonNr" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.vonNr.errors }">
        <div *ngIf="submitted && f.vonNr.errors">
          <div *ngIf="f.vonNr.errors.required">vonNr is required</div>
        </div>
      </div>
  
      <div class="form-group">
        <label>bisNr:</label>
        <input type="text" formControlName="bisNr" placeholder="bisNr" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.bisNr.errors }">
        <div *ngIf="submitted && f.bisNr.errors">
          <div *ngIf="f.bisNr.errors.required">bisNr is required</div>
        </div>
      </div>
      <div class="form-group">
        <label>PartnerId:</label>
        <select class="form-control" formControlName="partnerId" placeholder="PartnerId" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.fahrtId.errors }" >
            <option *ngFor="let partnerA of partner;let i = index" [value]="partnerA.id" 
>{{partnerA.partnername}} || {{partnerA.id}}</option>
        </select>

        <div *ngIf="submitted && f.fahrtId.errors">
          <div *ngIf="f.fahrtId.errors.required">Fahrt Nummer is required</div>
        </div>
      </div>
      <div class="form-group col-md-3">
        <label>Aktiviert:</label>
<!--        <input type="text" formControlName="aktiv" placeholder="Aktiv" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.aktiv.errors }"> -->
        <input type="checkbox" formControlName="aktiv" placeholder="Aktiv" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.aktiv.errors }"> 
        <div *ngIf="submitted && f.aktiv.errors">
          <div *ngIf="f.aktiv.errors.required">Aktiv is required</div>
        </div>
      </div>

      <button type="submit"  class="btn btn-info">Save</button>      
      <button type="button"  class="btn btn-default" (click)="cancelEdit()">Cancel</button>

    </form>
  </div>
