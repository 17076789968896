import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FahrtModel } from '../_model/FahrtModel';
import { AppGlobals } from '../app.globals';

@Injectable({
  providedIn: 'root'
})
export class FahrtService {

  constructor(private http: HttpClient,private _global: AppGlobals) { }

//  baseurl: string = "http://localhost:3000/api/";
  baseurl: string = this._global.baseAppUrl;

  getAllFahrten(){
    return this.http.get<FahrtModel[]>(this.baseurl + 'getAllFahrten');
  }

  getFahrtById(id: Number){
    return this.http.get<FahrtModel>(this.baseurl + 'getFahrtById' + '/' + id);
  }

  addFahrt(fahrt: FahrtModel){
    return this.http.post(this.baseurl + 'addFahrt', fahrt);
  }

  deleteFahrt(id: Number){
    return this.http.delete(this.baseurl + 'delFahrt' + '/' + id);
  }

  updateFahrt(fahrt: FahrtModel){
    return this.http.put(this.baseurl + 'updFahrt' + '/' + fahrt.id, fahrt);
  }
}
