<div class="col-md-6">
    <h2 class="text-center">Add Partner</h2>
    <form [formGroup]="addForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="form-group col-md-3">
          <label>Name:</label>
          <input type="text" formControlName="partnername" placeholder="Name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.partnername.errors }">
          <div *ngIf="submitted && f.partnername.errors">
            <div *ngIf="f.partnername.errors.required">Name is required</div>
          </div>
        </div>
        <div class="form-group col-md-3">
          <label>Aktiv:</label>
          <input type="checkbox" formControlName="aktiv" placeholder="Aktiv" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.aktiv.errors }"> 
          <div *ngIf="submitted && f.aktiv.errors">
            <div *ngIf="f.aktiv.errors.required">Aktiv is required</div>
          </div>
        </div>
      </div>
  
      <div class="form-group">
        <label>Adresse 1:</label>
      <textarea rows="6" formControlName="adresse1" placeholder="Adresse 1" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.adresse1.errors }" ></textarea>
        <div *ngIf="submitted && f.adresse1.errors">
          <div *ngIf="f.address1.errors.required">Adresse is required</div>
        </div>
      </div>
      <div class="form-group">
        <label>Adresse 2:</label>
      <textarea rows="6" formControlName="adresse2" placeholder="Adresse2" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.adresse2.errors }" ></textarea>
        <div *ngIf="submitted && f.adresse2.errors">
          <div *ngIf="f.address2.errors.required">Adresse is required</div>
        </div>
      </div>
  
      <div class="form-group">
        <label>Provision:</label>
        <input type="number" formControlName="provision" placeholder="Provision" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.provision.errors }">
        <div *ngIf="submitted && f.provision.errors">
          <div *ngIf="f.provision.errors.required">Provision is required</div>
        </div>
      </div>

      <button type="submit"  class="btn btn-info">Save</button>      
      <button type="button"  class="btn btn-default" (click)="cancelEdit()">Cancel</button>

    </form>
  </div>
