<div class="container col-md-12"> 
    <div class="form-group">
        <button (click)="addPartner()" class="btn btn-info">Add Partner</button>
        <input type="checkbox" id="showActive" name="showActive" [checked]="showActive" (change)="changedActive($event)"  style="margin-left: 15px"/>
        <label for="showActive">nur aktive</label>
        <label for="searchText" style="margin-left: 30px">Suche: </label>
        <select name="searchField" [(ngModel)]="searchField" class="form-control-sm">
            <option *ngFor="let feld of searchFieldList" 
               [value]="feld.val" >{{feld.key}}
            </option>
        </select>
        <input type="text" name="searchText" class="form-control-sm" [(ngModel)]="searchText" >
      </div> 
  <h2>All Partners</h2>  
  <div class="table-responsive table-container">  
    <table class="table table-light table-hover table-striped">  
      <thead class="thead-light">  
        <tr>  
          <th (click)="clickHeader('id')">ID
            <span *ngIf="sortType == 'id' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'id' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th (click)="clickHeader('partnername')">Name
            <span *ngIf="sortType == 'partnername' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'partnername' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th (click)="clickHeader('adresse1')">Adresse 1
            <span *ngIf="sortType == 'adresse1' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'adresse1' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th (click)="clickHeader('adresse2')">Adresse 2
            <span *ngIf="sortType == 'adresse2' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'adresse2' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th (click)="clickHeader('provision')">Provision
            <span *ngIf="sortType == 'provision' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'provision' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th (click)="clickHeader('aktiv')">Aktiv
            <span *ngIf="sortType == 'aktiv' && !sortReverse" class="fa fa-caret-down"></span>
            <span *ngIf="sortType == 'aktiv' && sortReverse" class="fa fa-caret-up"></span>
          </th> 
          <th>Action</th> 
        </tr>  
      </thead>  
      <tbody>  
        <tr *ngFor="let partner of partners | isactive: showActive | searchfilter : searchText : searchField | orderBy:sortType: sortReverse"> 
           
          <td>{{partner.id}}</td>  
          <td>{{partner.partnername}}</td>  
          <td>{{partner.adresse1}}</td>
          <td>{{partner.adresse2}}</td>
          <td>{{partner.provision | number :'1.2-2':'de'}}</td> 
<!--          <td>{{partner.aktiv}}</td>  --> 
          <td><input *ngIf="partner.aktiv == 1; else notChecked" type="checkbox" checked disabled/>
             <ng-template #notChecked>
               <input type="checkbox" unchecked disabled/>
             </ng-template>
          </td>           
          <td>  
            <button (click)="deletePartner(partner)" class="btn btn-info"> Delete</button>  
            <button (click)="updatePartner(partner)" style="margin-left: 20px;" class="btn btn-info"> Edit</button>  
          </td>  
        </tr>  
      </tbody>  
    </table>  
  </div> 
   
</div> 
