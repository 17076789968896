<div class="col-md-12">
    <h2 class="text-center">Add Fahrschein</h2>
    <form [formGroup]="addForm"  (ngSubmit)="onSubmit()">
      <div class="form-row">
          <div class=" col-md-3">
            <label>Fahrschein Nr:</label>
            <input autofocus type="text" id="id" formControlName="id" placeholder="Schein Nr" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.id.errors }" (blur)="scheinNrChanged($event)" [ngStyle]="f.id.value>'?'?{'background-color':'#f8d7da'}: {'background-color':'white'}">
            <div *ngIf="submitted && f.id.errors">
              <div *ngIf="f.id.errors.required">Schein Nr is required</div>
            </div>
          </div>
          <div class=" col-md-3">
            <label>Datum:</label>
            <input type="date" formControlName="datum" placeholder="Datum" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.datum.errors }" (change)="datumChanged($event)" (blur)="datumChanged($event)">
            <div *ngIf="submitted && f.datum.errors">
              <div *ngIf="f.datum.errors.required">Datum is required</div>
            </div>
          </div>
          <div class=" col-md-1">
            <label>Tag:</label>
            <input type="text" [attr.disabled]="true" formControlName="wochentag" placeholder="" class="form-control" >
          </div>
          <div class=" col-md-2">
            <label>Block Nr:</label>
            <input type="text" [attr.disabled]="true" formControlName="blockId" placeholder="" class="form-control" [ngStyle]="f.blockId.value==null?{'background-color':'#f8d7da'}: {'background-color':'#E9ECEF'}" >
     <!--       <label *ngIf="!redBlockId"  class="alert alert-danger"></label> -->
          </div>
          <div class=" col-md-3">
            <label>Partner:</label>
            <input type="text" [attr.disabled]="true" formControlName="Partnername" placeholder="Partner" class="form-control">
          </div>
      </div>  
      <div class="form-group">
        <label>Fahrt Nr:</label>
        <select class="form-control" formControlName="fahrtId" placeholder="Fahrt Nummer" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.fahrtId.errors }" (blur)="calcFieldChanged()">
            <option *ngFor="let fahrt of fahrten_display;let i = index" [value]="fahrt.id" 
>{{fahrt.fahrtnummer}} {{fahrt.fahrtname}} {{wochentage[fahrt.wochentag].name}}</option>
        </select>

        <div *ngIf="submitted && f.fahrtId.errors">
          <div *ngIf="f.fahrtId.errors.required">Fahrt Nummer is required</div>
        </div>
      </div>
      <div class="form-group">
        <label>Gast:</label>
        <input type="text" formControlName="gastName" placeholder="Gast" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.gastName.errors }">
        <div *ngIf="submitted && f.gastName.errors">
          <div *ngIf="f.gastName.errors.required">Gast is required</div>
        </div>
      </div>
  
      <div class="form-row">          
        <div class=" col-md-2">
            <label>Anzahl Erwachsene:</label>
            <input type="number" formControlName="personen" placeholder="0" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.personen.errors }" (blur)="calcFieldChanged()" (change)="calcFieldChanged()">
            <div *ngIf="submitted && f.personen.errors">
              <div *ngIf="f.personen.errors.required">Anzahl Erwachsene is required</div>
            </div>
        </div>
        <div class=" col-md-2">
            <label>Anzahl Kinder:</label>
            <input type="number" formControlName="kinder" placeholder="0" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.kinder.errors }" (blur)="calcFieldChanged()" (change)="calcFieldChanged()">
            <div *ngIf="submitted && f.kinder.errors">
              <div *ngIf="f.kinder.errors.required">Anzahl Kind is required</div>
            </div>
        </div>
        <div class=" col-md-2">
            <label>Preis Sonstiges:[EUR]</label>
            <input type="number" formControlName="sonstigesPreis" placeholder="0" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.sonstigesPreis.errors }" (blur)="calcFieldChanged()" (change)="calcFieldChanged()">
            <div *ngIf="submitted && f.sonstigesPreis.errors">
              <div *ngIf="f.sonstigesPreis.errors.pattern">Preis Sonstiges Format: x,xx</div>
            </div>
        </div>
        <div class=" col-md-6">
            <label>Bemerkung:</label>
            <input type="text" formControlName="sonstigesText" placeholder="Bemerkung" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.sonstigesText.errors }">
            <div *ngIf="submitted && f.sonstigesText.errors">
              <div *ngIf="f.sonstigesText.errors.required">Bemerkung is required</div>
            </div>
        </div>
      </div>
      <div class="form-row">          
        <div class=" col-md-2">
            <strong>
            <label>Summe:</label>
            </strong>
            <input type="number" formControlName="summe" placeholder="" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.summe.errors }" style="font-weight: bold;">
            <div *ngIf="submitted && f.summe.errors">
              <div *ngIf="f.summe.errors.required">Summe is required</div>
              <div *ngIf="f.summe.errors.nullValidator">Summe größer 0</div>
              <div *ngIf="f.summe.errors.pattern">Summe Format: x,xx</div>
            </div>
        </div>
        <div class="col-md-4">
            <label></label>
            <p *ngIf="f.SollSumme.value==f.summe.value"><img src="/assets/checkmark-48.png"></p>
        </div>
        <div class="col-md-2">
          <label>Referenz[EUR]:</label>
          <input type="number" [attr.disabled]="true" formControlName="SollSumme" placeholder="0" class="form-control" [ngStyle]="f.SollSumme.value!=f.summe.value?{'background-color':'#f8d7da'}: {'background-color':'#E9ECEF'}" >
        </div>
      </div>
      <br/>
      <div class="form-row"> 
        <div class="col-md-12">
            <div *ngIf="message" class="alert alert-danger">{{message}}</div>         
        </div>
      </div>
      <div class="form-row"> 
        <div class="col-md-12">
            <div *ngIf="success_message" class="alert alert-success">{{success_message}}</div>         
        </div>
      </div>
 
      <button type="submit"  class="btn btn-info">Save and Next</button>      
      <button type="button"  class="btn btn-info"  style="margin-left: 15px;" (click)="saveExit()">Save</button>      
      <button type="button"  class="btn btn-default" (click)="cancelEdit()">Cancel</button>
<!--      <button type="button"  class="btn btn-default" (click)="testReset()">TEST ONLY</button>
-->
    </form>
  </div>
