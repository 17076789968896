import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MonatsreportModel } from '../_model/MonatsreportModel';
import { AppGlobals } from '../app.globals';


@Injectable({
  providedIn: 'root'
})
export class OfficegenService {

  constructor(private http: HttpClient, private _global: AppGlobals) { }

  baseurl: string = this._global.baseAppUrl;
//  baseurl: string = "http://localhost:3000/api/";
//  baseurl: string = "/";

  createDocument(inParam: MonatsreportModel){
console.log(inParam)
//    var paramStr = url.encodeURI(inParam)   
    return this.http.post(this.baseurl + 'docx/',inParam, {responseType: 'blob'});
  }
}