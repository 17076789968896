import { Pipe, PipeTransform } from "@angular/core";
@Pipe( {
name: 'orderBy'
} )
export class OrderByPipe implements PipeTransform {
transform( array: Array<any>, orderField: string, orderType: boolean ): Array<string> {
    if (typeof array == 'undefined') {
        return array;
    }
    array.sort( ( a: any, b: any ) => {
        let ae = a[ orderField ];
        let be = b[ orderField ];
        if ( ae == undefined && be == undefined ) return 0;
        if ( ae == undefined && be != undefined ) return orderType ? 1 : -1;
        if ( ae != undefined && be == undefined ) return orderType ? -1 : 1;
        if ( ae == be ) return 0;
        // check if numeric sort
        let z = Number(ae)
        if ( !isNaN(Number(ae)) && !isNaN(Number(be)) ) {
          let z = ae - be
          return orderType ? (ae - be) : (be - ae);
        }
        //    
        return orderType ? (ae.toString().toLowerCase() < be.toString().toLowerCase() ? -1 : 1) : (be.toString().toLowerCase() < ae.toString().toLowerCase() ? -1 : 1);
    } );
    return array;
  }
}