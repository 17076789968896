<div class="col-md-6">
    <h2 class="text-center">Add Fahrt</h2>
    <form [formGroup]="addForm" (ngSubmit)="onSubmit()">
     <div class="row">
        <div class="form-group col-md-3">
          <label>Fahrt Nummer:</label>
          <input type="text" formControlName="fahrtnummer" placeholder="fahrtnummer" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.fahrtnummer.errors }">
          <div *ngIf="submitted && f.fahrtnummer.errors">
            <div *ngIf="f.fahrtnummer.errors.required">fahrtnummer is required</div>
          </div>
        </div>
        <div class="form-group col-md-3">
          <label>Aktiviert:</label>
  <!--        <input type="text" formControlName="aktiv" placeholder="Aktiv" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.aktiv.errors }">  -->
          <input type="checkbox" formControlName="aktiv" placeholder="Aktiv" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.aktiv.errors }"> 
          <div *ngIf="submitted && f.aktiv.errors">
            <div *ngIf="f.aktiv.errors.required">Aktiv is required</div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Fahrt Bezeichnung:</label>
        <input type="text" formControlName="fahrtname" placeholder="fahrtname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.fahrtname.errors }">
        <div *ngIf="submitted && f.fahrtname.errors">
          <div *ngIf="f.fahrtname.errors.required">Fahrtname is required</div>
        </div>
      </div>
  
      <div class="form-group">
        <label>Wochentag:</label>
<!--        <input type="text" formControlName="wochentag" placeholder="wochentag" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.wochentag.errors }">
-->
        <select class="form-control" formControlName="wochentag" placeholder="wochentag" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.wochentag.errors }">
            <option *ngFor="let tag of wochentage" [value]="tag.id">{{tag.id}} {{tag.name}}</option>
        </select>

        <div *ngIf="submitted && f.wochentag.errors">
          <div *ngIf="f.wochentag.errors.required">Wochentag is required</div>
        </div>
      </div>
  
      <div class="form-group">
        <label>Preis Erwachsene:</label>
        <input type="number" formControlName="preisPerson" placeholder="preisperson" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.preisPerson.errors }">
        <div *ngIf="submitted && f.preisPerson.errors">
          <div *ngIf="f.preisPerson.errors.required">Preis Erwachsene is required</div>
        </div>
      </div>
      <div class="form-group">
        <label>Preis Kinder:</label>
        <input type="number" formControlName="preisKind" placeholder="preiskind" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.preisKind.errors }">
        <div *ngIf="submitted && f.preisKind.errors">
          <div *ngIf="f.preisKind.errors.required">Preis Kind is required</div>
        </div>
      </div>

      <button type="submit"  class="btn btn-info">Save</button>      
      <button type="button"  class="btn btn-default" (click)="cancelEdit()">Cancel</button>

    </form>
  </div>
