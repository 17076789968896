import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AppGlobals } from '../../app.globals';
import { FahrscheinModel } from '../../_model/FahrscheinModel';
import { FahrscheinService } from '../../_service/fahrschein.service';
import { PartnerService } from '../../_service/partner.service';


@Component({
  selector: 'app-fahrschein-list',
  templateUrl: './fahrschein-list.component.html',
  styleUrls: ['./fahrschein-list.component.css']
})
export class FahrscheinListComponent implements OnInit {

  constructor(private fahrscheinService: FahrscheinService, private router: Router, private _global: AppGlobals) { }

  fahrscheine: FahrscheinModel[];
  showYear: boolean;
  currentYear: String;
  popoverMessage:String;

  searchText: string;
  searchFieldList: any[];
  searchField: string;

  sortType:string;
  sortReverse:boolean;

  ngOnInit() {
    this.popoverMessage = null;
	this.showYear = true;
    var now = new Date().toISOString().substr(0, 19);
    this.currentYear = now.substr(0,4)
    this.sortType = 'datum';
    this.sortReverse = false;
    this.searchFieldList = [{key:"Id",val:"id"},
      {key:"Fahrt Nr",val:"fahrtId"},
      {key:"Block Nr",val:"blockId"},
      {key:"Partner",val:"partnername"},
      {key:"Datum",val:"datum"},
      {key:"Summe",val:"summe"},
      {key:"Gast",val:"gastName"},
      {key:"Personen",val:"personen"},
      {key:"Kinder",val:"kinder"},
      {key:"Sonst Preis",val:"sonstigesPreis"},
      {key:"Sonst Text",val:"sonstigesText"} ]
    this.searchField = "partnername"
    this.getAllFahrscheine();
  }

  getAllFahrscheine(): void {
    this.fahrscheinService.getAllFahrscheine().subscribe(data=>{
      this.fahrscheine = data;
    });
  };
  

  addFahrschein(): void {
    this.router.navigate(['fahrschein-add']);
  }

  deleteFahrschein(fahrschein: FahrscheinModel){
    if(window.confirm('Are sure you want to delete this item ?')){
      this.fahrscheinService.deleteFahrschein(fahrschein.id).subscribe(data=>{
        console.log(data);
        this.getAllFahrscheine();
      });
    }
  }
  updateFahrschein(fahrschein: FahrscheinModel){
    localStorage.removeItem("fahrscheinId");
    localStorage.setItem("fahrscheinId", fahrschein.id.toString());
    this.router.navigate(['fahrschein-edit']);
  }

  clickHeader(inVal) {
      this.sortType = inVal;
      this.sortReverse = !this.sortReverse
  }
  changedYear(e:any) {    
    this.showYear = !this.showYear
  }
}
