import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../_model/User';
import { AppGlobals } from '../app.globals';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,private _global: AppGlobals) { }

//  baseurl: string = "http://localhost:3000/";
  baseurl: string = this._global.baseAppUrl;

  getAllUsers(){
    return this.http.get<User[]>(this.baseurl + 'Users');
  }

  getProductById(id: string){
    return this.http.get<User>(this.baseurl + 'Users' + '/' + id);
  }

  register(user: User){
    return this.http.post(this.baseurl + 'Users', user);
  }
/*
  deleteProduct(id: string){
    return this.http.delete(this.baseurl + 'Users' + '/' + id);
  }

  updateProduct(user: UserModel){
    return this.http.put(this.baseurl + 'Users' + '/' + user._id, user);
  }
*/  
}
